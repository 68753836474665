import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorldRoutingModule } from './world-routing.module';
import { AboutComponent } from './about/about.component';
import { IntroductionComponent } from './introduction/introduction.component';

@NgModule({
  imports: [
    CommonModule,
    WorldRoutingModule
  ],
  declarations: [AboutComponent, IntroductionComponent]
})
export class WorldModule { }

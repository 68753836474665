import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-hex',
  templateUrl: './hex.component.html',
  styleUrls: ['./hex.component.scss']
})
export class HexComponent implements OnInit, OnChanges {
  private _strokeColor: string;
  private _colorHue: number;
  @Input()
  public saturationMidpoint: number;
  @Input()
  public lightnessMidpoint: number;
  @Input()
  public template: string;

  public stops: IColorStop[];
  public backgroundGradientColorId: LinkableId;
  public backgroundGradientOffsetId: LinkableId;

  constructor() {
    this.colorhue = 0;
    this.saturationMidpoint = 0.74;
    this.lightnessMidpoint = 0.78;
    this.stops = [];
    this.template = HexGradientTemplate.none.toString();
  }

  ngOnInit() {
    this.backgroundGradientColorId = new LinkableId(pseudoRandomId('hexBkgnd'));
    this.backgroundGradientOffsetId = new LinkableId(pseudoRandomId('hexBkgnd'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateDerivedValues();
  }

  @Input()
  public get colorhue(): number {
    return this._colorHue;
  }

  public set colorhue(value: number) {
    this._colorHue = clampHue(value);
  }


  private populateDerivedValues() {
    this.stops.length = 0;
    let gradient: ITemplateGradient;
    const template: HexGradientTemplate = HexGradientTemplate[this.template];
    if (!template || template === HexGradientTemplate.none) {
      gradient = generateStandardGradient(this.colorhue, this.saturationMidpoint, this.lightnessMidpoint);
    } else {
      gradient = generateTemplateGradient(template);
    }
    gradient.stops.map(colorStop => this.stops.push(colorStop));
    this._strokeColor = `hsl(${gradient.baseHue},${percentString(0.3)},${percentString(0.2)})`;
  }

  public get strokeColor(): string {
    return this._strokeColor;
  }
}

function generateStandardGradient(baseHue, baseSaturation, baseLightness): ITemplateGradient {
  baseHue = clampHue(baseHue);
  baseSaturation = clampPercentage(baseSaturation);
  baseLightness = clampPercentage(baseLightness);

  const highlightSaturation = baseSaturation * 0.85;
  const lowlightSaturation = baseSaturation * 0.65;
  const highlightLightness = baseLightness * 1.15;
  const lowlightLightness = baseLightness * 0.65;

  return {
    baseHue: baseHue,
    stops: [
      new ColorStop(0, baseHue, highlightSaturation, highlightLightness),
      new ColorStop(0.1, baseHue, baseSaturation, baseLightness),
      new ColorStop(0.93, baseHue, baseSaturation, baseLightness),
      new ColorStop(1, baseHue, lowlightSaturation, lowlightLightness)
     ]
  };
}

enum ElementHue {
  aether = 212,
  air = 206,
  earth = 62,
  fire = 3,
  metal = 200,
  water = 220,
  wood = 130
}

function generateTemplateGradient(template: HexGradientTemplate): ITemplateGradient {
  switch (template) {
    case HexGradientTemplate.aether:
      return generateAetherGradient();
    case HexGradientTemplate.air:
      return generateStandardGradient(ElementHue.air, 0.74, 0.81);
    case HexGradientTemplate.earth:
      return generateStandardGradient(ElementHue.earth, 0.62, 0.6);
    case HexGradientTemplate.fire:
      return generateStandardGradient(ElementHue.fire, 0.74, 0.65);
    case HexGradientTemplate.metal:
      return generateStandardGradient(ElementHue.metal, 0.09, 0.6);
    case HexGradientTemplate.water:
      return generateStandardGradient(ElementHue.water, 0.74, 0.65);
    case HexGradientTemplate.wood:
      return generateStandardGradient(ElementHue.wood, 0.72, 0.59);
  }
}

function generateAetherGradient(): ITemplateGradient {
  const baseHue = 212;
  const pale = 0.18;
  return {
    baseHue: baseHue,
    stops: [
      new ColorStop(0, baseHue, 0.21, 0.92),
      new ColorStop(0.1, baseHue, 0.49, 0.79),
      new ColorStop(0.13, ElementHue.air, pale, 0.81),
      new ColorStop(0.284, ElementHue.earth, pale, 0.76),
      new ColorStop(0.438, ElementHue.fire, pale, 0.83),
      new ColorStop(0.592, ElementHue.metal, pale, 0.74),
      new ColorStop(0.746, ElementHue.water, pale, 0.83),
      new ColorStop(0.9, ElementHue.wood, pale, 0.79),
      new ColorStop(0.93, baseHue, 0.54, 0.79),
      new ColorStop(1, baseHue, 0.31, 0.48)
    ]
  };
}

interface ITemplateGradient {
  baseHue: number;
  stops: IColorStop[];
}

export enum HexGradientTemplate {
  none   = 'none',
  aether = 'aether',
  air    = 'air',
  earth  = 'earth',
  fire   = 'fire',
  metal  = 'metal',
  water  = 'water',
  wood   = 'wood'
}

interface IColorStop {
  color: string;
  opacity: number;
  offset: number;
  id: string;
}

export class LinkableId {
  constructor(public id: string) {
  }
  public get prefixedId(): string {
    return '#' + this.id;
  }
  public get asUrlLink(): string {
    return 'url(' + this.prefixedId + ')';
  }
}

class ColorStop implements IColorStop {
  public id: string;
  constructor(
    public offset: number,
    public hue: number,
    public saturation: number,
    public lightness: number,
    public opacity: number = 1.0
  ) {
    this.id = pseudoRandomId('stop');
  }

  public get color(): string {
    const saturationString = percentString(this.saturation);
    const lightnessString = percentString(this.lightness);
    return `hsl(${this.hue},${saturationString},${lightnessString})`;
  }
}

function percentString(percent: number) {
  return (percent * 100).toFixed(1).toString() + '%';
}

function pseudoRandomId(prefix: string): string {
  return prefix + (Math.random() * 1000000).toFixed(0).toString();
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

function clampPercentage(value: number): number {
  return clamp(value, 0, 1);
}

function clampHue(hue: number): number {
  return clamp(hue, 0, 359.999999);
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { IntroductionComponent } from './introduction/introduction.component';

const routes: Routes = [
  { path: 'world/about', component: AboutComponent },
  { path: 'world/introduction', component: IntroductionComponent },
  { path: 'world',   redirectTo: 'world/introduction', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorldRoutingModule { }

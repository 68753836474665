import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WorldModule } from './world/world.module';
import { SevenElementsModule } from './seven-elements/seven-elements.module';
import { ModuleNavigatorComponent } from './module-navigator/module-navigator.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HexComponent } from './hex/hex.component';

@NgModule({
  declarations: [
    AppComponent,
    ModuleNavigatorComponent,
    HexComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    SevenElementsModule,
    WorldModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
